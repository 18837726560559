import { FORM_TYPE_NAMES, SELECTOR_VALUE_NAMES } from '@/constants/P2P'
import { CURRENCY_SHORT_CODES, FIAT_CURRENCY_NAMES } from '@/constants/auto-exchange'

export const getOfferPairName = offer => {
  const { owned_value, wanted_value } = offer;
  const { BUY, SELL } = SELECTOR_VALUE_NAMES;
  const offerType = owned_value.type === FORM_TYPE_NAMES.INTERNAL
    ? BUY
    : SELL;
  console.log('getOfferPairName', { offer })
  const isSell = offerType === SELL
  const firstPair = CURRENCY_SHORT_CODES[(isSell ? wanted_value : owned_value).currency]
  const secondPair = CURRENCY_SHORT_CODES[(isSell ? owned_value : wanted_value).currency]
  return `${firstPair}/${secondPair}`
}
